import {useEffect} from 'react'
import Router from 'next/router'

function Redirect({href, replace = false}) {
  useEffect(() => {
    if (replace) {
      Router.replace(href, href)
    } else {
      Router.push(href, href)
    }
  }, [href, replace])

  return null
}

export default Redirect
