/**
 * Used to automatically log-in from www.amblea.fr.
 */

import React from 'react'

import {useRouter} from 'next/router'

import {LoaderCard} from '../components/loader'
import {withApollo} from '../lib/with-apollo'
import {useAuth, useMainMt, withGenericAuthProtection} from '../components/stack/auth'
import Redirect from '../lib/redirect'

const useQueryStatus = () => {
  const mainMt = useMainMt()

  const {isLoading, isEmailConfirmed, user, mfuAcls} = useAuth()

  if (isLoading) {
    return 'LOADING'
  }

  if (!user?.id) {
    return 'NEED_LOGIN'
  }

  if (!isEmailConfirmed) {
    return 'UNVERIFIED'
  }

  if (!mainMt && !mfuAcls.length) {
    return 'UNLINKED'
  }

  return 'CONNECTED'
}

function Page() {
  const router = useRouter()
  const mainMt = useMainMt()

  const {mfuAcls, signIn} = useAuth()

  const queryStatus = useQueryStatus()

  React.useEffect(() => {
    if (queryStatus === 'NEED_LOGIN') {
      signIn()
    }
  }, [queryStatus, router, signIn])

  const redirect = (() => {
    if (queryStatus === 'UNVERIFIED') {
      return '/account/unverified'
    }

    if (queryStatus === 'UNLINKED') {
      return '/account/unlinked'
    }

    if (queryStatus !== 'CONNECTED') {
      return '/'
    }

    if (mfuAcls.length) {
      return '/es/dashboard'
    }

    if (mainMt && mainMt.mt.type === 'DISPATCHER') {
      return process.env.AMBLER_BO_URL
    }

    if (mainMt) {
      return '/ts/dashboard'
    }

    return '/'
  })()

  return (
    <>
      {queryStatus === 'LOADING' && <LoaderCard message="Connexion en cours..." />}
      {queryStatus === 'NEED_LOGIN' && <LoaderCard message="Veuillez vous connecter" />}

      {queryStatus === 'UNLINKED' && (
        <>
          <LoaderCard message="Redirection en cours..." />
          <Redirect href={redirect} replace />
        </>
      )}

      {queryStatus === 'UNVERIFIED' && (
        <>
          <LoaderCard message="Redirection en cours..." />
          <Redirect href={redirect} replace />
        </>
      )}

      {queryStatus === 'CONNECTED' && (
        <>
          <LoaderCard message="Connexion en cours..." />
          <Redirect href={redirect} replace />
        </>
      )}
    </>
  )
}

export default withApollo()(withGenericAuthProtection(Page))
